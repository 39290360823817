import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import routes from './router';
import { useUserInfoStore, useSystemInfoStore } from '@/store';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const systemInfoStore = useSystemInfoStore();
  const userInfoStore = useUserInfoStore();
  if (to.meta.title) {
    document.title = to.meta.title as string;
  } else {
    document.title = '博客管理后台';
  }
  if (to.meta.menu) {
    systemInfoStore.$patch((store) => {
      store.menu = to.meta.menu;
    });
  }

  // let token = userInfoStore.token
  let token = sessionStorage.getItem('token');
  if (to.meta.requireAuth) {
    if (token && token != undefined) {
      next();
    } else {
      next({
        path: '/login',
      });
    }
  } else {
    next();
  }
});

export default router;

import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/about',
    name: 'about',
    meta: {
      title: '关于',
      requireAuth: false,
      menu: '0',
    },
    component: () => import('../views/AboutView.vue'),
  },
  {
    path: '/login',
    name: 'login',

    meta: {
      title: '登录',
      requireAuth: false,
      menu: '0',
    },
    component: () => import('../views/login/LoginView.vue'),
  },
];

const frameIn: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'layout',
    meta: {
      requireAuth: true,
      menu: '0',
    },
    component: () => import('@/components/layout/Layout.vue'),
    children: [
      {
        path: 'home',
        name: 'home',
        meta: {
          requireAuth: true,
          menu: '1',
        },
        component: () => import('@/views/home/HomeView.vue'),
      },
      {
        path: 'article',
        name: 'article',
        meta: {
          requireAuth: true,
          menu: '2',
        },
        component: () => import('@/views/article/ArticleView.vue'),
        children: [
          {
            path: '',
            name: 'articleData',
            meta: {
              requireAuth: true,
              menu: '2',
            },
            component: () => import('@/views/article/ArticleDataView.vue'),
          },
          {
            path: 'edit/:id',
            name: 'articleEdit',
            meta: {
              requireAuth: true,
              menu: '2',
            },
            component: () => import('@/views/article/ArticleEditView.vue'),
          },
          {
            path: 'write',
            name: 'articleWrite',
            meta: {
              requireAuth: true,
              menu: '2',
            },
            component: () => import('@/views/article/ArticleWriteView.vue'),
          },
        ],
      },
      {
        path: '/category',
        name: 'category',
        meta: {
          requireAuth: true,
          menu: '4',
        },
        component: () => import('@/views/category/CategoryView.vue'),
      },
      {
        path: '/setting',
        name: 'setting',
        meta: {
          requireAuth: true,
          menu: '3',
        },
        component: () => import('@/views/setting/SettingView.vue'),
      },
      {
        path: '/message',
        name: 'message',
        meta: {
          requireAuth: true,
          menu: '5',
        },
        component: () => import('@/views/message/MessageView.vue'),
      },
      {
        path: '/friend',
        name: 'friend',
        meta: {
          requireAuth: true,
          menu: '6',
        },
        component: () => import('@/views/friend/FriendView.vue'),
      },
    ],
  },
];

export default [...routes, ...frameIn];

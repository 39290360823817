import axios, { AxiosRequestConfig } from 'axios';
import { useUserInfoStore } from '@/store';
import EnMessage from '@/components/message/message';
import router from '@/router';

export function request(config: AxiosRequestConfig) {
  const instance = axios.create({
    baseURL: '/api',
    timeout: 10000,
  });
  // 请求拦截器
  instance.interceptors.request.use(
    (config) => {
      const token = sessionStorage.getItem('token');
      // const userInfoStore = useUserInfoStore()
      // const token = userInfoStore.token
      config.headers = {
        Authorization: token ? 'Bearer ' + token : '',
      };
      return config;
    },
    (error) => {
      console.log(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      // console.log(response);
      // return response.data
      return response;
    },
    (error) => {
      switch (error.response.status) {
        case 401:
          sessionStorage.removeItem('token');
          EnMessage('未登录或登录过期', 'warn');
          setTimeout(() => {
            router.push('/login');
          }, 1500);
          break;
        case 403:
          sessionStorage.removeItem('token');
          EnMessage('权限不足', 'warn');
          setTimeout(() => {
            router.push('/login');
          }, 1500);
          break;
        default:
          return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );
  return instance(config);
}

export default request;

// 处理请求响应

import Message from '@/components/message/message';

interface ErrorObject {
  [key: number]: string;
}

export const responseErrorEnum = (status: number, popup: boolean = false) => {
  const Errors: ErrorObject = {
    1001: '错误',
    1002: '用户名不存在',
    1003: '登录失败',
    1004: '用户名存在',
    1005: '注册失败',
    1006: '更新失败',
    1007: '密码不匹配',
    2004: '文章没有找到',
    2010: '文章更新失败',
    2011: '文章发布失败',
    2012: '文章操作失败',
    3001: '分类不存在',
    3002: '分类操作失败',
    3003: '默认分类不能删除',
    4001: '评论失败',
    4002: '文章不存在',
    4003: '查看评论权限不足',
    5001: '友链申请不存在',
    5002: '申请已处理',
    5003: '网站连通性检测失败',
    5004: '识别码不存在',
    5005: '友链操作失败',
    5006: '友链更新失败',
    6001: '留言暂不展示',
    6002: '留言不能为空',
    6003: '操作失败',
    7001: '计划操作失败',
    7002: '计划不存在',
    10001: '登录过期，请重新登录',
    10002: '访问令牌不可用',
    10003: '访问令牌不可以信任',
    10004: '访问令牌校验失败',
    20001: '不支持的加密方式',
    20002: '不支持的类型',
  };

  let errMsg = Errors[status] || '未知错误';
  if (popup) {
    Message(errMsg, 'error');
  }
  return errMsg;
};

export const reponseIsSuccess = (status: number): boolean => {
  return status == 0;
};

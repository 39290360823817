import { createVNode, render } from 'vue';

import EnMessage from './EnMessage.vue';

const div = document.createElement('div');

div.setAttribute('class', 'en-message-container');

document.body.appendChild(div);

let timer: number = 0;

export default (text: string, type: string) => {
  const vnode = createVNode(EnMessage, { text: text, type: type });
  render(vnode, div);
  clearTimeout(timer);

  timer = setTimeout(() => {
    render(null, div);
  }, 2000);
};

<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
</template>

<style lang="scss">
@font-face {
  font-family: 'JasonHandwriting';
  src: url('@/assets/font/JasonHandwriting1.woff');
  // src: url('https://zeniein.oss-cn-shenzhen.aliyuncs.com/JasonHandwriting1.woff2');
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'JasonHandwriting';
}

input,
button {
  border: none;
  outline: none;
  background: none;
}
.section {
  padding: 20px;
  background-color: #fff;
  border-radius: 32px;
}
</style>

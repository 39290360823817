
import { onMounted, ref } from 'vue';
export default {
  name: 'EnMessage',
  props: {
    text: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'success',
    },
  },
  setup() {
    const chooceType = (typeName: string) => {
      switch (typeName) {
        case 'success':
          return 'success';
        case 'info':
          return 'info';
        case 'warn':
          return 'warn';
        case 'error':
          return 'error';
        default:
          return 'success';
      }
    };

    const visible = ref(false);

    onMounted(() => {
      visible.value = true;
      setTimeout(() => {
        visible.value = false;
      }, 2000);
    });

    return {
      visible,
      chooceType,
    };
  },
};
